<template>
  <div class="main">
    <div class="container">     
      <div class="recommendList">
        <ul v-for="(item,index) in list"
                    :key="index">
          <li @click="onCopy(item.random_id)">{{item.post_title}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.random_id}}</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'DjhrqRecommendList',
  data () {
    return {
      limit: 100,
      list: [],
    };
  },
  created() {
    this.onLoad()
  },
  methods: {
    onLoad () {
      let that = this
      that.$axios.get('/api/portal/lists/recommended', { params: { limit: that.limit,order:'-id'  } }).then(res => {
       that.list = res.data.data.list
      }).catch((error) => {
        console.log(error);
      });
    },
    onCopy(code){
      this.$copyText(code.toString()).then(() => {
        this.value = code.toString()
        this.$toast.success('复制编码成功')
        
      }).catch(() => {
        this.$toast.fail('复制失败');
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .recommendList{
    margin: 20px auto;
    text-align: center;
    font-size: 0.4rem;
  } 
}
</style>